
import { computed, defineComponent } from '@vue/runtime-core';
import { translate } from '@/features/i18n/i18n-translate';

export default defineComponent({
    setup: () => ({
        home: computed(() => translate('app', 'footer', 'links', 'home')),
        position: computed(() => translate('app', 'footer', 'links', 'position')),
    }),
});
